@import './variable.scss';

@font-face {
	font-family: 'Anuphan-Regular';
	// src: url("../assets/fonts/OTF/Anuphan_Light.otf");
	src: url("../assets/fonts/OTF/Anuphan-Regular.ttf");
  }

//   @font-face {
// 	font-family: 'Mitr-Light';
// 	// src: url("../assets/fonts/OTF/Anuphan_Light.otf");
// 	src: url("../assets/fonts/Mitr/Mitr-Light.ttf");
//   }


html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: $bg;
	font-family: 'Anuphan-Regular', 'sans-serif' !important;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
  }
  
  .trigger:hover {
	color: #1890ff;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #7eca9c !important;
	}
  
  .logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.3);
	margin: 16px;
  }

  .ant-layout-sider {
	  background: #393e46 !important;
  }

  .ant-menu.ant-menu-dark, .ant-menu-dark, .ant-menu.ant-menu-dark  {
    color: rgba(255, 255, 255, 0.65);
    background: #393e46 !important;
}

.ant-menu-sub {
	color: rgba(255, 255, 255, 0.65);
    background: #424449 !important;
}


.custom-search-model {
	// input {
	// 	border-radius: 15px;
	// }
	.ant-input-group-addon {
		background: none;
    	padding-left: 5px !important;
		
	}
}



  
//   .site-layout {
// 	background: #e8e9eb;
//   }

  .site-layout-background {
	background: #f0f2f5;
  }

  .ant-layout-header {
	background: #e8e9eb !important;
  }

  .ant-picker, .ant-select-selector, .ant-modal-content {
	border-radius: 10px !important;
}

.ant-select-dropdown {
	border-radius: 10px !important;
}

.ant-pagination-item-active {
	border-radius: 10px !important;
}

.ant-btn {
	border-radius: 32px !important;
}

.ant-modal-header {
	border-radius: 10px 10px 0 0 !important;
}

textarea.ant-input {
	border-radius: 10px !important;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.ant-modal {
	width: 800px !important;
}

.ant-card-head-title {
	padding: 8px 0 !important;
}

.ant-card-extra {
	padding: 0 !important;
}

.ant-tree-show-line .ant-tree-switcher {
    background: #f0f2f5 !important;
}

.ant-tree-list {
	background: #f0f2f5;
}

.ant-card-body {
    padding: 10px 24px !important;
}

.ant-table-thead > tr > th {
	font-weight: bold !important;
	text-align: center !important;
}

.ant-modal-close-x {
	justify-content: unset !important;
}

.ant-table-tbody tr:nth-child(odd) td  { 
	background: #f5f8f6  !important;
}
.ant-upload.ant-upload-select-picture-card {
	width: 9em !important;
    height: 9em !important;
    margin-top: 0.3em !important;
    margin-left: 0.8em !important;
}
.ant-upload-list-picture-card-container {
	width: 9em !important;
    height: 9em !important;
    margin-top: 0.4em !important;
}

.modal-small {
	width: 40em !important;
	margin-top: 12em !important; 
	// justify-content:center !important ;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    white-space: pre-line;
}

.button-checkbox {
	border-radius: 10px !important;
	padding: 0 !important;
}

.checkbox-button-checked{
	border-radius: 10px !important;
	background-color: #1890ff !important;
	padding: 0 !important;
}

.ant-dropdown-menu {
    max-height: 250px;
    overflow: auto;
}

.show { display: block !important }
.hide { display: none !important }

.table-reorder {
	cursor: pointer;
}

/*==== dragula ====*/
.ant-table-row .draggable {
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	font-size: 1.2rem;
	// transform: rotate(90deg);
  }
  
  
  .gu-mirror {
	background-color: rgba(16, 142, 233, 0.15);
	position: fixed;
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
  }
  
/*==== dragula ====*/